import React from 'react'

const ArrowUpright = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <g clip-path="url(#clip0_3402_10904)">
          <path d="M9.91634 4.08325L4.08301 9.91659" stroke="#1D1E4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.66699 4.08325H9.91699V9.33325" stroke="#1D1E4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_3402_10904">
            <rect width="14" height="14" fill="white"/>
          </clipPath>
        </defs>
    </svg>
  )
}

export default ArrowUpright