import React from "react";
import { IoBulb, IoCloud, IoGitNetwork } from "react-icons/io5";
import "./WhyAttend.css";

const WhyAttend = () => {
  return (
    <section className="why-attend py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <span
              className="text-center text-uppercase text-nlco-secondary"
              style={{ fontSize: "14px" }}
            >
              Join the event
            </span>
            <br />
            <span className="text-center text-uppercase text-nlco-secondary">
              Why Attend
            </span>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 col-sm-12 mb-4 card">
            <div className="card bg-white why-attend-card2">
              <div className="card-body text-center">
                <h5 className="card-title2 card-title-icon">
                  <IoBulb />
                </h5>
                <h5 className="card-title card-title-text2 text-uppercase">
                  Imagine
                </h5>
                <p className="card-text">
                  Hear from experts and business leaders.. Be inspired by how
                  they harness the power of the mind to create their own
                  opportunities. Get actionable strategies to help jumpstart and
                  accelerate your career.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="card bg-white why-attend-card">
              <div className="card-body text-center">
                <h5 className="card-title card-title-icon">
                  <IoCloud />
                </h5>
                <h5 className="card-title card-title-text text-uppercase">
                  Learn
                </h5>
                <p className="card-text">
                  Attend carefully curated sessions that speak to critical
                  issues young Africans grapple with today. Learn from seasoned
                  speakers who will share first-hand experience and key lessons
                  from their career journey.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-4">
            <div className="card bg-white why-attend-card1">
              <div className="card-body text-center">
                <h5 className="card-title card-title-icon">
                  <IoGitNetwork />
                </h5>
                <h5 className="card-title card-title-text text-uppercase">
                  Network
                </h5>
                <p className="card-text">
                  Meet like minds from all over the world. Expand your network.
                  Join a support a vibrant and supportive community of
                  Professionals just like you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyAttend;
