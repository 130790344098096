import React, { useEffect, useState } from "react";
import "./FeaturedSpeaker.css";
import { featuredSpeakers } from "../Constants/PeopleConstants";



// ========== modal for speakers ============= //

import IconButton from "@mui/material/IconButton";

import {
  IoArrowUp,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
} from "react-icons/io5";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";

const FeaturedSpeaker = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [selectedHS, setSelectedHS] = useState("4.0");
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);

  const handleSpeakerClick = (e, speaker) => {
    e.preventDefault();

    setSelectedSpeaker(speaker);
  };

  const handleCloseModal = () => {
    setSelectedSpeaker(null);
  };

  useEffect(() => {
    const cards = document.querySelectorAll(".featured-speaker-card");

    const handleImageLoad = () => {
      const loadedImages = document.querySelectorAll(
        ".featured-speaker-card img"
      );
      if (loadedImages.length === cards.length) {
        setImagesLoaded(true);
      }
      loadedImages.forEach((image) => {
        // console.log("Image height:", image.offsetHeight);
        // console.log("Image naturalHeight:", image.naturalHeight);
      });
    };

    cards.forEach((card) => {
      const image = card.querySelector("img");
      image.addEventListener("load", handleImageLoad);
      // console.log("EL ADDED:", image);
    });

    handleImageLoad();

    // Cleanup
    return () => {
      cards.forEach((card) => {
        card.querySelector("img").removeEventListener("load", handleImageLoad);
      });
    };
  }, []);

  useEffect(() => {
    if (imagesLoaded) {
      const cards = document.querySelectorAll(".featured-speaker-card");

      cards.forEach((card) => {
        const image = card.querySelector("img");
        if (image.complete) {
          const imageHeight = image.offsetHeight;
          const overlayDiv = card.querySelector(".featured-speaker-overlay");
          // console.log("OTHER DATA", card, imageHeight, overlayDiv);
          overlayDiv.style.height = `${imageHeight}px`;
        } else {
          setImagesLoaded(false);
        }
      });
    }
  }, [imagesLoaded]);

  useEffect(() => {
    const cardCarousel = document.querySelector(".cards-carousel");
    const dotIndicator = document.querySelector(".dot-indicator");

    // Clear existing dot indicators
    dotIndicator.innerHTML = "";

    for (let i = 0; i < cardCarousel.children.length; i++) {
      const dot = document.createElement("li");
      dot.setAttribute("data-index", i);
      dotIndicator.appendChild(dot);
    }

    const handleScroll = () => {
      const cardWidth = cardCarousel.offsetWidth;
      const scrollLeft = cardCarousel.scrollLeft;
      const activeIndex = Math.round(scrollLeft / cardWidth);
      const dots = dotIndicator.querySelectorAll("li");

      dots.forEach((dot) => dot.classList.remove("active"));
      dots[activeIndex].classList.add("active");
    };

    cardCarousel.addEventListener("scroll", handleScroll);

    return () => {
      cardCarousel.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getHeight = () => {
    return "226px";
  };

  const handleHSButtonClick = (hs) => {
    setSelectedHS(hs);
  };

  return (
    <section className="featured-speaker" id="speakers">
      <div className="row justify-content-center bg-white p-5">
        <h2 className="text-uppercase text-center text-nlco-primary">
          Featured speakers
        </h2>
        <div className="lead text-center d-none">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8 col-12 d-flex text-center hs-cohorts">
          <button
            onClick={() => handleHSButtonClick("4.0")}
            className={`btn col mx-1 ${selectedHS === "4.0" ? "active" : ""}`}
          >
            Handshake 4.0
          </button>
          <button
            onClick={() => handleHSButtonClick("3.0")}
            className={`btn col mx-1 ${selectedHS === "3.0" ? "active" : ""}`}
          >
            Handshake 3.0
          </button>
          <button
            onClick={() => handleHSButtonClick("2.0")}
            className={`btn col mx-1 ${selectedHS === "2.0" ? "active" : ""}`}
          >
            Handshake 2.0
          </button>

          <button
            onClick={() => handleHSButtonClick("1.0")}
            className={`btn col mx-1 ${selectedHS === "1.0" ? "active" : ""}`}
          >
            Handshake 1.0
          </button>
        </div>
      </div>
      <div className="row justify-content-center bg-white p-5">
        <div
          className="col-md-12 d-md-flex cards-carousel"
          style={{ cursor: "pointer" }}
        >
          {featuredSpeakers.map(
            (fs) =>
              fs.batch === selectedHS && (
                <div
                  class="card col-md-2 mx-4 featured-speaker-card"
                  key={fs.id}
                  onClick={(e) => handleSpeakerClick(e, fs)}
                >
                  <img
                    src={fs.image}
                    class="card-img-top"
                    alt="..."
                    height={"226px"}
                  />
                  <div
                    className="featured-speaker-overlay"
                    style={{ height: getHeight() }}
                  >
                    <div className="pt-3 px-4 desc">
                      {fs.desc}
                      <div className="d-flex w-75 justify-content-between mt-2 pb-2">
                        <a href="#">
                          <span className="fs-social-icons">
                            <IoLogoTwitter />
                          </span>
                        </a>
                        <a href="#">
                          <span className="fs-social-icons">
                            <IoLogoFacebook />
                          </span>
                        </a>
                        <a href="#">
                          <span className="fs-social-icons">
                            <IoLogoLinkedin />{" "}
                          </span>
                        </a>
                        <a
                          target="blank"
                          href={`https://instagram.com/${fs.instagram}`}
                        >
                          <span className="fs-social-icons">
                            <IoLogoInstagram />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <h5 class="card-title text-uppercase text-muted mt-3 nlco-fct">
                      {fs.title}
                    </h5>
                    <div className="d-flex mt-3 justify-content-between card-subtitle">
                      <h5 class="text-capitalize">{fs.name}</h5>
                      <IoArrowUp className="rotate-90 text-end" />
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        <ul class="dot-indicator d-flex d-sm-none"></ul>
      </div>

      {selectedSpeaker && (
        <Modal
          show={selectedSpeaker != null}
          onHide={handleCloseModal}
          backdrop="false"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Dialog data-bs-config={{ backdrop: true }}>
            <Modal.Header closeButton>
              <Modal.Title>Handshake {selectedHS} Speaker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={12} md={4}>
                    <Image
                      src={selectedSpeaker.image}
                      className="img-fluid"
                      rounded
                    />
                  </Col>
                  <Col xs={12} md={8} className="mt-3 mt-sm-0">
                    <h6 className="text-muted text-capitalize">
                      {selectedSpeaker.title}
                    </h6>
                    <h2>{selectedSpeaker.name}</h2>
                    <p className="text-muted">{selectedSpeaker.desc}</p>

                    <a
                      target="blank"
                      href={`https://facebook.com/${selectedSpeaker.facebook}`}
                    >
                      <IconButton>
                        <IoLogoFacebook sx={{ height: 30, width: 30 }} />
                      </IconButton>
                    </a>

                    <a
                      target="blank"
                      href={`https://instagram.com/${selectedSpeaker.instagram}`}
                    >
                      <IconButton>
                        <IoLogoInstagram sx={{ height: 30, width: 30 }} />
                      </IconButton>
                    </a>
                    <a
                      target="blank"
                      href={`https://linkedin.com/${selectedSpeaker.linkedIn}`}
                    >
                      <IconButton>
                        <IoLogoLinkedin sx={{ height: 30, width: 30 }} />
                      </IconButton>
                    </a>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal.Dialog>
        </Modal>
      )}
    </section>
  );
};

export default FeaturedSpeaker;
